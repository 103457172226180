import profile from "../assets/profile.png";
import phone from "../assets/phone.svg";
import clinic from "../assets/clinic.svg";
import firefighters from "../assets/firefighters.svg";
import police from "../assets/police.svg";
import policeMan from "../assets/police-man.svg";
import emergency from "../assets/emergency.svg";
import ContactBox from "./elements/ContactBox";

export default function Contacts() {
  return (
    <div id="contacts" className="relative section-4-middle">
      <div className="px-5 md:container mx-auto pt-10 md:pt-40 space-y-10">
        <p className="text-primary font-bold text-3xl lg:text-center">Información de contacto</p>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          <div className="col-span-2 border border-primary rounded-2xl p-5 bg-card">
            <p className="text-primary text-xl font-semibold mb-10">
              Si necesitas asistencia no dudes en comunicarte conmigo o con las entidades correspondientes.
            </p>
            <div className="flex space-x-3">
              <img src={profile} alt="Anfitrion" className="w-[70px] object-fill rounded-full" />
              <div className="flex flex-col justify-evenly">
                <p className="text-primary font-semibold text-lg">Anfitrión</p>
                <p className="text-primary font-semibold text-sm">Adriana Jimenez</p>
              </div>
            </div>
          </div>

          <ContactBox
            imageSrc={phone}
            title="Anfitrión"
            description="Comunicate con nosotros."
            number="+51 980510220"
          />

          <ContactBox
            imageSrc={clinic}
            title="Clínica"
            description="Comunicate en caso de asistencia médica."
            number="+51 980510220"
          />

          <ContactBox
            imageSrc={firefighters}
            title="Bomberos"
            description="Comunicate en caso de incendios."
            number="+51 980510220"
          />

          <ContactBox
            imageSrc={police}
            title="Policía"
            description="Comunicate en caso de robo o hurto."
            number="+51 980510220"
          />

          <ContactBox
            imageSrc={policeMan}
            title="Serenazgo"
            description="Comunicate en caso de robo o hurto."
            number="+51 980510220"
          />

          <ContactBox
            imageSrc={emergency}
            title="Emergencia"
            description="Comunicate en caso de emergencia médica."
            number="+51 980510220"
          />
        </div>
      </div>
    </div>
  );
}
