import livingRoom from "../assets/living-room.svg";
import room from "../assets/room.svg";
import kitchen from "../assets/kitchen.svg";

const commonAreas = [
  {
    src: livingRoom,
    alt: "Sala de estar",
    text: "Sala de estar",
  },
  {
    src: room,
    alt: "Sala de estar",
    text: "Gimnasio",
  },
  {
    src: kitchen,
    alt: "Sala de estar",
    text: "Zona de parrillas",
  },
];

export default commonAreas;
