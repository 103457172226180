import Detail from "./components/Detail";
import Main from "./components/Main";
import Navbar from "./components/Navbar";
import Restaurants from "./components/Restaurants";
import Attractions from "./components/Attractions";
import Contacts from "./components/Contacts";
import Reservation from "./components/Reservation";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App overflow-hidden">
      <Navbar />
      <Main />
      <Detail />
      <Restaurants />
      <Attractions />
      <Contacts />
      <Reservation />
      <Footer />
    </div>
  );
}

export default App;
