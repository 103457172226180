import { useState } from "react";
import departmentImages from "../../carousels/department";

export default function MainCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = (movement: number) => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex + movement >= departmentImages.length) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  return (
    <>
      <div className="hidden md:block relative mt-5 lg:mt-12">
        <div className="overflow-hidden relative w-full">
          <div className="flex transition-transform duration-500 ease-in-out space-x-3 lg:space-x-5" style={{ transform: `translateX(calc(-${currentIndex} * (33.33% + 1.25rem)))` }}>
            {departmentImages.map((image, index) => (
              <img
                key={index}
                src={image["src"]}
                alt={image.alt}
                className="w-1/3 h-64 object-cover rounded-lg"
              />
            ))}
          </div>
        </div>

        <button
          onClick={prevSlide}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-carousel text-white text-xs rounded-full w-[25px] h-[25px] flex justify-center items-center"
        >
          ❮
        </button>
        <button
          onClick={() => nextSlide(3)}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-carousel text-white text-xs rounded-full w-[25px] h-[25px] flex justify-center items-center"
        >
          ❯
        </button>
      </div>

      <div className="md:hidden relative mt-3 px-5">
        <div className="overflow-hidden relative w-full">
          <div className="flex transition-transform duration-500 ease-in-out space-x-3 lg:space-x-5" style={{ transform: `translateX(calc(-${currentIndex} * (50% + 1.25rem)))` }}>
            {departmentImages.map((image, index) => (
              <img
                key={index}
                src={image["src"]}
                alt={image.alt}
                className="w-1/2 h-32 object-cover rounded-lg"
              />
            ))}
          </div>
        </div>

        <button
          onClick={prevSlide}
          className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-carousel text-white text-xs rounded-full w-[25px] h-[25px] flex justify-center items-center"
        >
          ❮
        </button>
        <button
          onClick={() => nextSlide(2)}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-carousel text-white text-xs rounded-full w-[25px] h-[25px] flex justify-center items-center"
        >
          ❯
        </button>
      </div>
    </>
  )
}