import { useState } from 'react';
import museum from "../assets/museum-1.png";
import Map from './elements/Map';
import RecommendationCard from './elements/RecommendationCard';
import attraction from "../assets/attraction.svg";

const center = {
  lat: -12.12010885258013,
  lng: -77.03319172743983,
};

export default function Attractions() {
  const [markers, setMarkers] = useState<google.maps.places.PlaceResult[]>([]);

  const fetchNearbyRestaurants = (map: google.maps.Map, position: google.maps.LatLngLiteral) => {
    const service = new google.maps.places.PlacesService(map)

    const request: google.maps.places.PlaceSearchRequest = {
      location: new google.maps.LatLng(position.lat, position.lng),
      radius: 800,
      keyword: "tourist_attraction OR museum OR park OR amusement_park OR art_gallery"
    }

    const fetchAllPages = (results: google.maps.places.PlaceResult[], pagination: google.maps.places.PlaceSearchPagination | null) => {
      setMarkers((prevMarkers) => [...prevMarkers, ...results]);

      if (pagination && pagination.hasNextPage) {
        setTimeout(() => {
          pagination.nextPage()
        }, 2000)
      }
    }

    service.nearbySearch(request, (results, status, pagination) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        setMarkers(results)
        fetchAllPages(results, pagination)
      }
    })
  }

  const handleMapLoad = (map: any) => {
    fetchNearbyRestaurants(map, center);
  };

  return (
    <div id="turistics" className="relative section-2-middle">
      <div className="px-5 md:container mx-auto pt-10 md:pt-40">
        <p className="text-primary font-bold text-3xl mb-10">Atractivos Turísticos</p>

        <div className="flex space-y-10 md:space-y-0 md:space-x-10 flex-col md:flex-row">
          <div className="w-full md:w-1/2 rounded-xl min-h-[450px]">
            <Map center={center} onLoad={handleMapLoad} markers={markers} icon={attraction} />
          </div>

          <div className="w-full md:w-1/2">
            <div className="grid grid-cols-2 gap-10">
              <RecommendationCard
                name="Museo Larco"
                description="El Museo Arqueológico Rafael Larco Herrera, conocido como Museo Larco, es un museo de arte precolombino."
                image={museum}
              />

              <RecommendationCard
                name="Museo Larco"
                description="El Museo Arqueológico Rafael Larco Herrera, conocido como Museo Larco, es un museo de arte precolombino."
                image={museum}
              />

              <RecommendationCard
                name="Museo Larco"
                description="El Museo Arqueológico Rafael Larco Herrera, conocido como Museo Larco, es un museo de arte precolombino."
                image={museum}
              />

              <RecommendationCard
                name="Museo Larco"
                description="El Museo Arqueológico Rafael Larco Herrera, conocido como Museo Larco, es un museo de arte precolombino."
                image={museum}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}