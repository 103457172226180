import { useState } from "react";
import BurgerIcon from "./icons/Burger";
import CloseIcon from "./icons/Close";
import logo from "../assets/logo.png";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = (): void => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div className="fixed top-0 bg-white overflow-hidden z-50 w-full">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <a href="/">
              <img src={logo} className="w-[120px] md:w-[150px]" alt="IntiApartments Logo" />
            </a>
          </div>

          <div className="hidden md:flex space-x-4">
            <a href="#details" className="text-primary px-3 py-2 text-sm font-semibold">
              Detalle
            </a>
            <a href="#restaurants" className="text-primary px-3 py-2 text-sm font-semibold">
              Restaurantes
            </a>
            <a href="#turistics" className="text-primary px-3 py-2 text-sm font-semibold">
              Act. Turísticos
            </a>
            <a href="#contacts" className="text-primary px-3 py-2 text-sm font-semibold">
              Contactos
            </a>
            <a href="#reservation" className="text-primary px-3 py-2 text-sm font-semibold">
              Reserva
            </a>
          </div>

          <div className="md:hidden flex items-center">
            <button
              type="button"
              onClick={toggleMenu}
              className="text-primary font-bold"
            >
              {isMenuOpen ? <CloseIcon /> : <BurgerIcon />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col items-center space-y-5 top-[64px] pt-10">
          <a
            href="#details"
            className="text-primary text-lg font-medium"
            onClick={() => setIsMenuOpen(false)}
          >
            Detalle
          </a>
          <a
            href="#restaurants"
            className="text-primary text-lg font-medium"
            onClick={() => setIsMenuOpen(false)}
          >
            Restaurantes
          </a>
          <a
            href="#turistics"
            className="text-primary text-lg font-medium"
            onClick={() => setIsMenuOpen(false)}
          >
            Act. Turísticos
          </a>
          <a
            href="#contacts"
            className="text-primary text-lg font-medium"
            onClick={() => setIsMenuOpen(false)}
          >
            Contactos
          </a>
          <a
            href="#reservation"
            className="text-primary text-lg font-medium"
            onClick={() => setIsMenuOpen(false)}
          >
            Reserva
          </a>
        </div>
      )}
    </div>
  );
};
