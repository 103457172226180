import room from "../assets/room.svg";
import kitchen from "../assets/kitchen.svg";
import livingRoom from "../assets/living-room-2.svg";

const departmentImages = [
  {
    src: room,
    alt: "Habitación",
  },
  {
    src: livingRoom,
    alt: "Sala de estar",
  },
  {
    src: kitchen,
    alt: "Cocina",
  },
  {
    src: kitchen,
    alt: "Cocina",
  },
  {
    src: livingRoom,
    alt: "Sala de estar",
  },
];

export default departmentImages;
