import { useState } from 'react';
import restaurant from "../assets/restaurante-1.png";
import Map from './elements/Map';
import RecommendationCard from './elements/RecommendationCard';
import food from "../assets/food.svg";

const center = {
  lat: -12.12010885258013,
  lng: -77.03319172743983,
};

export default function Restaurants() {
  const [markers, setMarkers] = useState<google.maps.places.PlaceResult[]>([]);

  const fetchNearbyRestaurants = (map: google.maps.Map, position: google.maps.LatLngLiteral) => {
    const service = new google.maps.places.PlacesService(map);

    const request: google.maps.places.PlaceSearchRequest = {
      location: new google.maps.LatLng(position.lat, position.lng),
      radius: 800,
      type: "restaurant",
    };

    service.nearbySearch(request, (results, status, _pagination) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        setMarkers(results)
      }
    });
  }

  const handleMapLoad = (map: any) => {
    fetchNearbyRestaurants(map, center);
  };

  return (
    <div id="restaurants" className="relative section-3-middle">
      <div className="px-5 md:container mx-auto pt-10 md:pt-40">
        <p className="text-primary font-bold text-3xl mb-10">Restaurantes</p>

        <div className="flex space-y-10 md:space-y-0 md:space-x-10 flex-col md:flex-row">
          <div className="w-full md:w-1/2 rounded-xl min-h-[450px]">
            <Map center={center} onLoad={handleMapLoad} markers={markers} icon={food} />
          </div>

          <div className="w-full md:w-1/2">
            <div className="grid grid-cols-2 gap-10">
              <RecommendationCard
                name="Maido"
                description="En cada plato, una historia; en cada sabor, un recuerdo; en cada sonrisa compartida, se une esta historia."
                image={restaurant}
              />

              <RecommendationCard
                name="Maido"
                description="En cada plato, una historia; en cada sabor, un recuerdo; en cada sonrisa compartida, se une esta historia."
                image={restaurant}
              />

              <RecommendationCard
                name="Maido"
                description="En cada plato, una historia; en cada sabor, un recuerdo; en cada sonrisa compartida, se une esta historia."
                image={restaurant}
              />

              <RecommendationCard
                name="Maido"
                description="En cada plato, una historia; en cada sabor, un recuerdo; en cada sonrisa compartida, se une esta historia."
                image={restaurant}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}