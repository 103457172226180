import livingRoom from "../assets/living-room.svg";
import MainCarousel from "./elements/MainCarousel";

export default function Main() {
  return (
    <div className="relative section-1 mt-[4rem]">
      <div className="px-5 md:container mx-auto section-1-middle">
        <div className="flex flex-col md:flex-row pt-10 space-x-10 items-center">
          <img src={livingRoom} alt="Sala de estar" className="px-5 md:px-0 md:w-[70%] lg:w-[68%] rounded-lg" />

          <div className="hidden md:block w-[30%] space-y-5">
            <div className="flex flex-col justify-center space-y-5">
              <p className="text-primary pt-5 md:pt-0 text-2xl lg:text-6xl font-semibold">Reserva con nosotros</p>
              <p className="hidden lg:block text-primary text-xl font-semibold lg:pr-5 py-3">
                Contamos con un <br /> espacioso apartamento en <br /> una de las mejores zonas <br /> de lima.
              </p>
              <p className="lg:hidden text-primary text-lg md:text-sm pr-5 md:pr-0 font-semibold py-3">
                Contamos con un espacioso apartamento en una de las mejores zonas de lima.
              </p>
            </div>
            <div>
              <a href="#reservation" className="bg-secondary text-white font-bold md:text-xs lg:text-lg py-3 px-10 rounded-xl mt-3">
                RESERVA AQUÍ
              </a>
            </div>
          </div>
        </div>

        <MainCarousel />

        <div className="md:hidden w-full space-y-2 px-5">
          <div className="flex flex-col justify-center space-y-2">
            <p className="text-primary pt-5 text-2xl font-semibold">Reserva con nosotros</p>
            <p className="text-primary text-lg pr-3 font-semibold pb-2">
              Contamos con un espacioso apartamento en una de las mejores zonas de lima.
            </p>
          </div>
          <div>
            <a href="#reservation" className="bg-secondary text-white font-bold text-sm py-3 px-5 rounded-xl">
              RESERVAR
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}