export default function Footer() {
  return (
    <div className="px-5 md:container mx-auto pt-10 md:pt-40 font-semibold">
      <div className="mx-auto border-t-2 flex flex-row justify-between border-footer pt-1">
        <p className="text-footer ml-2">Inti Aparments</p>
        <a href="https://ru-solutions.com/" target="_blank" rel="noreferrer" className="text-footer italic mr-2">
          Develop by{" "}
          <span className="not-italic text-rusolutions ml-2">RU Solutions</span>
        </a>
      </div>
    </div>
  );
}
