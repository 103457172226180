import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import home from "../../assets/home.svg";
import { useEffect, useState } from 'react';

interface Props {
  center: {
    lat: number
    lng: number
  }
  onLoad: (map: any) => void
  markers: google.maps.places.PlaceResult[]
  icon: string
}

const Map: React.FC<Props> = ({ center, onLoad, markers, icon }) => {
  const [visibleHome, setVisibleHome] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisibleHome(true);
    }, 500);
  }, []);

  const openGoogleMaps = (lat?: number, lng?: number) => {
    const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(mapsUrl, '_blank');
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyB1rIWYJSoRX7AY5ojWIr2XsBpHlClIym4" libraries={["places"]}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%', minHeight: '450px' }}
        center={center}
        zoom={16}
        onLoad={onLoad}>
        {visibleHome && (<Marker position={center} icon={{ url: home }} />)}

        {markers.map((place, index) => (
          place.geometry?.location && (
            <Marker
              key={index}
              position={place.geometry.location}
              onClick={() =>
                openGoogleMaps(
                  place.geometry?.location?.lat(),
                  place.geometry?.location?.lng()
                )
              }
              icon={{ url: icon }}
            />
          )
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;
